<template>
	<div class="results">

		<div class="results__slider__wrapper" :class="[blockInteraction ? 'blocked' : '']">
			<flickity ref="flickity" :options="flickityOptions">
				<div v-for="(result, index) in lessonResult.results" :key="index" :class="hasComponent(result.type) ? 'results__item' : ''">
					<component v-bind:is="getComponent(result.type)" :data="result" />
				</div>
				<Level v-if="lessonResult.pointsEarned > 0" :data="lessonResult"/>
			</flickity>
		</div>
		<ResultBar :lessonResult="lessonResult"/>

	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import Flickity from 'vue-flickity';
	import Results from '~/modules/Results';
	import Level from "../../Results/Level";
	import LessonProgress from '~/modules/Learn/Lessons/LessonProgress.vue'
	import ResultBar from "~/modules/Results/ResultBar";

	export default {
		name: 'Result',
		components: {
			Flickity,
			Level,
			LessonProgress,
			ResultBar,
		},

		data() {
			return {
				currentViewType: '',
				blockInteraction: true,
				flickityOptions: {
					prevNextButtons: false,
					pageDots: false,
					wrapAround: false,
					groupCells: false,
					pauseAutoPlayOnHover: false,
					cellSelector: '.results__item',
					autoPlay: 3000
				}
			}
		},


		computed: {
			...mapState ('learn', ['lessonResult']),
			...mapState ('client', ['profile'])
		},


		mounted: function() {

			this.isFirstSlide = true;

			// handle flickity events
			this.$nextTick(() => {


				this.$refs.flickity.on('select', (i) => {
					const c = this.$refs.flickity.$children[i];
					if(typeof c !== "undefined") this.currentViewType = c.data.type;

					// disable interaction for first slide
					if(i === 0 && this.isFirstSlide === true){
						this.blockInteraction = true;
						this.isFirstSlide = false;
					} else {
						this.blockInteraction = false;
					}

				});

				this.$refs.flickity.on('settle', (i) => {

					const f = this.$refs.flickity;

					// call tween function
					if(typeof f.$children[i] !== 'undefined' && typeof f.$children[i].tween === 'function'){
						f.$children[i].tween.call();
					}

					// stop slider animation
					if(f.selectedIndex() === f.cells().length-1){
						f.stopPlayer();
					}

				});

				// trigger first cell
				this.$refs.flickity.selectCell(0);
			});
		},

		methods: {
			hasComponent: function(type){ return Results.hasOwnProperty(type); },
			getComponent: function (type) {
				return this.hasComponent(type) ? Results[type] : false;
			}
		}
	}

</script>

<style lang="scss">

	@import 'flickity/css/flickity.css';

	.results {

		display: flex;
		flex-direction: column;
		min-height: 100%;

		background: #444756 radial-gradient(circle at bottom center, #444756 0%, #191927 70%);
		color: $white;

		&__slider__wrapper {
			flex: 1;

			&.blocked {
				@include is-notouch;
			}
		}

		&__item {
			width: 100%;
		}

	}

	.result {

		overflow: hidden;
		margin: 0 auto;
		max-width: rem-calc(400px);

		&__wrapper {
			@include h-gap;
			padding-top: $gutter;
		}

		&__title, &__description {
			@include is-list-title;
			margin-bottom: $quarter-gutter;
			line-height: $line-height;
		}

		&__title {}

		&__description {
			font-weight: $font-weight-bold;
			color: $white;
			white-space: pre;
		}
	}

</style>
