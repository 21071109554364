<template>

	<header class="topbar" :class="[center ? 'topbar--center' : '']">
		<div class="topbar__wrapper">
			<div class="topbar__item topbar__item--spacer"></div>
			<router-link class="topbar__item topbar__item--logo" to="/home">
				<img class="topbar__logo" src="~/assets/images/iqoslogo.svg" alt="IQOS">
			</router-link>
			<transition name="fade">
			<section class="topbar__item topbar__item--rootline" v-if="showTitles">
				<div class="topbar__headline">
					<span class="topbar__headline__previous">{{l10n.component_module}}</span>
					<span class="topbar__headline__current">{{this.module.title}}</span>
				</div>
			</section>
			</transition>
			<div class="topbar__item topbar__item--navigation">
				<NavigationBar additionalClass="navbar--top" />
			</div>
			<router-link class="topbar__item topbar__item--badge" to="/profile">
				<BadgeLevel />
			</router-link>
		</div>
	</header>
</template>

<script>
	import BadgeLevel from './BadgeLevel';
	import NavigationBar from '~/components/NavigationBar/NavigationBar';
	import {mapState} from 'vuex';

	export default {
		name: 'TopBar',
		components: {
			BadgeLevel,
			NavigationBar
		},
		computed: {
			...mapState('learn', ['module']),
			...mapState ('global', ['l10n']),
			center: function(){
				return ['Home', 'Quiz'].indexOf(this.$route.name) !== -1;
			},
			showTitles: function(){
				return ['Module', 'Action'].indexOf(this.$route.name) !== -1 && this.l10n && this.module.title;
			}
		},
	}
</script>

<style lang="scss">

	.topbar {

		$root: &;

		flex: 0 0 $top-bar-height;
		height: $top-bar-height;
		z-index: 1;

		background-image: linear-gradient(180deg, #161926 2%, #424554 100%);
		color: $white;

		@include tablet {
			height: $top-bar-height-tablet;
			flex-basis: $top-bar-height-tablet;
		}

		&__wrapper {
			@include h-gap;
			height: 100%;

			align-items: stretch;
			display: flex;
			justify-content: space-between;
		}

		&__item {
			align-items: center;
			display: flex;
			flex: 1;

			&--spacer {
				flex: 0;
				@at-root #{$root}--center & {
					flex: 1;
				}

				@include tablet {
					flex: 0!important;
				}
			}

			&--logo {
				flex: 0;
				@at-root #{$root}--center & {
					flex: 1;
					justify-content: center;
				}

				@include tablet {
					flex: 0!important;
				}
			}

			&--rootline {
				@at-root #{$root}--center & {
					flex: 0;
				}

				@include tablet {
					flex: 1!important;
				}
			}

			&--navigation {
				display: none;

				@include tablet {
					flex: 1 0 auto;
					display: block;
				}
			}

			&--badge {
				justify-content: flex-end;
				@include tablet {
					flex: 0;
					margin-left: $gutter;
				}
				@include desktop {
					margin-left: $big-gutter;
				}
			}

			&--empty {
				width: 0;
				flex: 0;
			}
		}

		&__logo {
			height: rem-calc(28);
			padding-right: $gutter;

			@include tablet {
				height: rem-calc(30);
				padding-right: $big-gutter;
			}
		}

		&__headline {

			line-height: 1.3;
			@include tablet {
				display: flex;
			}

			> * {
				@include is-textoverflow;
				display: block;
			}

			&__previous {
				font-size: $font-size-small;
				text-transform: uppercase;
				color: $primary-light;

				@include tablet {
					margin-right: $half-gutter;
				}
			}

			&__current {
				font-size: $font-size-normal;
				font-weight: $font-weight-bold;
			}

			> * {
				@include tablet {
					font-size: rem-calc(14);
				}
			}

		}

	}

</style>
