<template>
	<div class="themes">

		<ListWithTitle :data="quiz.themes">
			<template #title>
				{{ quiz.title }}
			</template>
			<template #list="{data}">
				<ThemeItem :data="data" />
			</template>
		</ListWithTitle>

		<!-- TODO: later
		<div class="themes__more">
			<a href="#">Alle Module auf einen Blick</a>
		</div>

		<div class="themes__tags">
			<h2 class="list__title">Module nach Stichwortern</h2>

			<div class="taglist">
				<a href="#" class="tag">Reinigung</a>
				<a href="#" class="tag">iqos3</a>
				<a href="#" class="tag">iqo2.4</a>
				<a href="#" class="tag">Vertrie</a>
				<a href="#" class="tag">Batterie</a>
			</div>

		</div>
		-->

	</div>
</template>

<script>
	import {mapState, mapActions} from 'vuex'
	import ListWithTitle from '~/components/General/ListWithTitle';
	import ThemeItem from '../Themes/ThemeItem.vue';

	export default {
		name: 'Quiz',
		components: {
			ListWithTitle,
			ThemeItem
		},
		computed: {
			...mapState ('learn', ['quiz'])
		},
		created() {
			this.getThemesAll();
		},
		methods: {
			...mapActions ('learn', ['getThemesAll'])
		}
	}
</script>

<style lang="scss" scoped>
	.list {
		margin-bottom: 0;
	}
</style>

<style lang="scss">

	.themes {

		$root: &;

		&__more, &__tags {
			@include h-gap;
		}

		&__more {
			color: $primary;
			text-decoration: underline;
			font-size: $font-size-normal;
		}

		&__tags {
			margin-top: $big-gutter;
			margin-bottom: $half-gutter;
		}

	}

</style>
