<template>
	<nav class="navbar" :class="navClass">
		<NavigationBarButton :label="l10n.nav_home" to="/home">
			<template #icon>
				<svg width="26" height="26" xmlns="http://www.w3.org/2000/svg">
					<path class="stroke icon__home" d="M11.814 5.562a2.185 2.185 0 0 1 2.371 0h0l6.593 4.245a2.252 2.252 0 0 1 1.026 1.894h0v13.511H4.196v-13.51c0-.77.387-1.484 1.026-1.895h0zM13 14.546l-.179.005c-1.718.101-3.082 1.652-3.082 3.55h0v7.111h6.522v-7.11c0-1.964-1.46-3.556-3.261-3.556h0z" />
				</svg>
			</template>
		</NavigationBarButton>
		<NavigationBarButton :label="l10n.nav_themes" to="/learn/themes">
			<template #icon>
				<svg class="icon__themes" width="26" height="26" xmlns="http://www.w3.org/2000/svg">
					<path class="stroke icon__themes__row1" d="M25.537 7.05H8.004"/>
					<circle class="stroke icon__themes__row1" cx="3.35" cy="7.05" r="2.25"/>
					<path class="stroke icon__themes__row2" d="M25.537 14.827H8.004"/>
					<circle class="stroke icon__themes__row2" cx="3.35" cy="14.827" r="2.25"/>
					<path class="stroke icon__themes__row3" d="M25.537 22.604H8.004"/>
					<circle class="stroke icon__themes__row3" cx="3.35" cy="22.604" r="2.25"/>
				</svg>

			</template>
		</NavigationBarButton>
		<NavigationBarButton :label="l10n.nav_profile" to="/profile">
			<template #icon>
				<svg width="26" class="icon__profile" height="26" xmlns="http://www.w3.org/2000/svg">
					<path class="stroke" d="M4.75 25.17a8.5 8.5 0 0 1 17 0" stroke-linejoin="round"/>
					<path class="stroke" d="M18.25 10.75a5 5 0 1 1-10 0 5 5 0 0 1 10 0z" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</template>
		</NavigationBarButton>
	</nav>
</template>

<script>
	import {mapState} from 'vuex';
	import NavigationBarButton from './NavigationBarButton.vue';

	export default {
		name: 'NavigationBar',

		components: {
			NavigationBarButton
		},

		created: function() {
			setTimeout(() => { this.fakeCounter = 2; }, 3000);
		},

		data: function () {
			return {
				fakeCounter: 0
			}
		},

		computed: {
			...mapState ('global', ['l10n']),
			navClass: function () {
				let additionalClass = this.$attrs.additionalClass || '';
				return (this.l10n.nav_home && this.$route.meta.hideNavigationBar !== true) ? additionalClass + ' navbar--visible' : additionalClass + '';
			}
		},
	}
</script>

<style lang="scss">

	.navbar {

		display: flex;
		align-items: stretch;
		margin-top: auto;
		justify-content: center;
		z-index: 1;

		height: 0;
		transition: height $speed ease-out;
		transition-property: height;

		overflow: hidden;

		&--top {
			justify-content: flex-end;
		}

		&--dark, &--bottom {
			background-color: $secondary-alternative;
		}

		&--bottom {
			@include tablet {
				display: none;
			}
		}

		&--visible {
			height: $navbar-height;
		}
	}

</style>

<style lang="scss" scoped>

	$speed: .2s;

	@mixin active {
		.navbar__item--active & {@content}
	}

	.stroke {
		fill: transparent;
		stroke: $light;
		stroke-width: 1.5;

		@include active { stroke: $primary; }
	}

	.fill {
		fill: $light;
		@include active { fill: $primary; }
	}

	.icon {

		&__home {
			transition: fill-opacity $speed ease-in-out;
			fill: $primary;
			fill-opacity: 0;
			@include active { fill-opacity: 1; }
		}

		&__themes {

			$t: &;

			circle {
				transition: fill-opacity $speed ease-in-out;
				fill: $primary;
				fill-opacity: 0;
				@at-root #{$t}__row2 { transition-delay: $speed/2!important;  }
				@at-root #{$t}__row3 { transition-delay: $speed!important;  }
				@include active { fill-opacity: 1; }
			}

			path {
				transition: transform $speed ease-in-out;
				@at-root #{$t}__row2 { transition-delay: $speed/2!important;  }
				@at-root #{$t}__row3 { transition-delay: $speed!important;  }
				@include active {
					transform: translateX(-3px);
				}
			}
		}

		&__inbox {

			&__border {
				transform: scaleY(1);
				transform-origin: center bottom;
				transition: transform $speed ease-out;
				@include active {  transform: scaleY(.86); }
			}

			&__top {
				transform: perspective(80rem) rotateX(-180deg) translateY(-7.58px) scaleY(1);
				transform-origin: center 10px;
				transition: transform $speed ease-out;

				@include active { transform: perspective(80rem) rotateX(0deg) translateY(-7.58px) scaleY(1); }
			}

			&__topline {
				opacity: 1;
				transition: opacity 0s ease-out;
				transition-delay: $speed/3;
				@include active { opacity: 0; }
			}

			&__openline {
				stroke-width: 2px;
				transform: translateY(3.2px) scaleY(.7);
			}

			&__post {
				opacity: .5;
				transform: scale(0, .2);
				transform-origin: center 19px;
				transition: none $speed ease-out;
				transition-property: opacity, transform;

				@include active {
					transform: scale(1, 1);
					transition-delay: $speed / 2;
					opacity: 1;
				}
			}
		}

		&__profile {
			path {
				transition: fill-opacity $speed ease-in-out;
				fill: $primary;
				fill-opacity: 0;
				@include active { fill-opacity: 1; }
			}

		}

	}


</style>
