import baseService from '../../services/baseService';

const initialState = {
	home: {},
	quiz: {},
	theme: {},
	modulesUnfinished: {},
	modulesLatest: {},
	module: {},
	lesson: {},
	actions: null,
	action: null,
	actionAnswer: null,
	actionResultForAnswer: null,
	actionAnswerStatus: 'idle',
	actionsStatus: [],
	currentActionNum: 0,
	lessonResult: null,
	cachedThemes: [],
	cachedModules: [],
	lessonReturn: {path: '', title: ''}
};

const mutations = {
	setHome: (state, home) => {
		state.home = home;
	},
	setQuiz: (state, quiz) => {
		state.quiz = quiz;
	},
	setTheme: (state, theme) => {
		state.theme = theme;
	},
	setModulesUnfinished: (state, modulesUnfinished) => {
		state.modulesUnfinished = modulesUnfinished;
	},
	setModulesLatest: (state, modulesLatest) => {
		state.modulesLatest = modulesLatest;
	},
	setModule: (state, module) => {
		state.module = module;
	},
	setLesson: (state, lesson) => {
		state.lesson = lesson;
	},
	setActions: (state, actions) => {
		state.actions = actions;
	},
	setAction: (state, action) => {
		state.action = action;
	},
	setActionAnswer: (state, actionAnswer) => {
		state.actionAnswer = actionAnswer;
	},
	setActionResultForAnswer: (state, actionResultForAnswer) => {
		state.actionResultForAnswer = actionResultForAnswer;
	},
	actionAnswerStatus: (state, actionAnswerStatus) => {
		state.actionAnswerStatus = actionAnswerStatus;
	},
	actionsStatus: (state, actionsStatus) => {
		// important: reset actionsStatus, otherwise vue does not trigger
		state.actionsStatus = null;
		state.actionsStatus = actionsStatus;
	},
	currentActionNum: (state, currentActionNum) => {
		state.currentActionNum = currentActionNum;
	},
	setLessonResult: (state, result) => {
		state.lessonResult = result;
	},
	addCachedTheme: (state, theme) => {
		state.cachedThemes.push(theme);
	},
	addCachedModule: (state, module) => {
		state.cachedModules.push(module);
	},

	setLessonReturn: (state, obj) => { state.lessonReturn = obj },
};

const getters = {
	getLessonReturn: (state) => state.lessonReturn
};

const actions = {

	getHome: ({commit}) => {
		baseService
			.getHome ()
			.then (res => {
				commit ('setHome', res);
			})
			.catch (err => {
				console.error ('getHome error', err)
			})
	},

	getThemesAll: ({commit}) => {

		baseService
			.getThemesAll ()
			.then (res => {
				commit('setQuiz', res);
			})
			.catch (err => {
				console.error('getThemesAll error', err)
			})
	},

	getTheme: ({commit, state}, id) => {
		let cached = state.cachedThemes.filter(item => item.id === parseInt(id));
		if (cached.length > 0) {
			commit('setTheme', cached[cached.length - 1]);
		} else {
			commit('setTheme', {});
		}

		baseService
			.getTheme (id)
			.then (res => {
				commit('setTheme', res);
				commit('addCachedTheme', res);
			})
			.catch (err => {
				console.error('getTheme error', err)
			})
	},

	getModule: ({commit, state}, id) => {
		let cached = state.cachedModules.filter (item => item.id === parseInt (id));

		if (cached.length > 0) {
			commit('setModule', cached[cached.length - 1]);
		} else {
			commit('setModule', {});
		}

		baseService
			.getModule(id)
			.then(res => {
				commit('setModule', res);
				commit('addCachedModule', res)
			})
			.catch (err => {
				console.error ('getModule error', err)
			})
	},

	getModulesUnfinished: ({commit}, limit) => {
		baseService
			.getModulesUnfinished(limit)
			.then(res => {
				commit ('setModulesUnfinished', res);
			})
			.catch (err => {
				console.error('getModulesUnfinished error', err)
			})
	},

	getModulesLatest: ({commit}, limit) => {
		baseService
			.getModulesLatest (limit)
			.then(res => {
				commit('setModulesLatest', res);
			})
			.catch(err => {
				console.error ('getModulesLatest error', err);
			})
	},

	getLesson: ({commit, dispatch}, id) => {
		commit ('setLesson', {});
		commit ('setAction', {});
		commit('currentActionNum', 0);
		commit('actionsStatus', []);

		baseService
			.getLesson (id)
			.then (res => {
				commit('setLesson', res);
				commit('setActions', res.actions);
				// set the first action as an entry point to the questionnaire
				commit('setAction', res.actions[0]);
				commit('setModule', res.parent);

				// prepare the lesson progress bar
				let actionsStatus = [];
				for (let i = 0; i < res.actions.length; i++) {
					actionsStatus[i] = {
						subtype: res.actions[i].subtype,
						numTries: 0,
						result: ''
					};
				}
				commit('actionsStatus', actionsStatus);
			})
			.catch (err => {
				console.error ('getLesson error', err);
			})
	},

	getAction: ({commit, state}, id) => {
		commit ('setAction', {});
		baseService
			.getAction (id).then (res => {
			commit('setAction', res);
		}).catch (err => {
			console.error ('getAction error', err);
		})
	},

	getActionResultForAnswer: ({commit}, o) => {
		return baseService
			.setActionAnswer (o).then (res => {
				commit ('setActionResultForAnswer', res);
			}).catch (err => {
				commit ('actionAnswerStatus', 'idle');
			})
	},

	setLessonReturn: ({commit, state}, {path, title = ''}) => {
		commit( 'setLessonReturn', {path, title} );
		return {path, title};
	},

};

export default {
	namespaced: true,
	state: initialState,
	mutations,
	getters,
	actions
}
