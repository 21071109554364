import axios from './axios.js'
import errorHandler from './errorHandling.js'

const URL = '/api';

export default {

	auth: (query) => {
		const formData = new FormData ();
		formData.append ('query', query);
		return axios.post (`${URL}/auth`, formData)
			.then (res => res)
			.catch (err => err)
	},
	setAuthToken: (token) => {
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
		axios.defaults.withCredentials = true;
		localStorage.setItem ('user-token', token)
	},
	getProfile: () => {
		return axios.get (`${URL}/client`)
			.then (res => res.data)
			.catch (err => err)
	},
	getHome: () => {
		return axios
			.get (`${URL}/home`)
			.then (res => res.data)
			.catch (err => err)
	},
	getThemesAll: () => {
		return axios
			.get (`${URL}/theme`)
			.then (res => res.data)
			.catch (err => err)
	},
	getTheme: (id) => {
		return axios
			.get (`${URL}/theme/${id}`)
			.then (res => res.data)
			.catch (err => err)
	},
	getModule: (id) => {
		return axios
			.get (`${URL}/module/${id}`)
			.then (res => res.data)
			.catch (err => err)
	},
	getModulesUnfinished: (max) => {
		const limit = max ? '?limit=' + max : '';
		return axios
			.get (`${URL}/modules/unfinished${limit}`)
			.then (res => res.data)
			.catch (err => err)
	},
	getModulesLatest: (max) => {
		const limit = max ? '?limit=' + max : '';
		return axios
			.get (`${URL}/modules/latest${limit}`)
			.then (res => res.data)
			.catch (err => err)
	},
	getLesson: (id) => {
		return axios
			.get (`${URL}/lesson/${id}`)
			.then (res => res.data)
			.catch (err => err)
	},
	getAction: (id) => {
		return axios
			.get (`${URL}/action/${id}`)
			.then (res => res.data)
			.catch (err => err)
	},
	setActionAnswer: (o) => {
		const formData = new FormData ();
		formData.append ('answer', o.answerId);
		return axios
			.post (`${URL}/action/${o.id}`, formData)
			.then (res => res.data)
			.catch (err => err)
	}
}
