<template>
	<transition name="fadein">

		<div v-if="isLoaded" class="home">

			<!-- Header && SmartLearn -->
			<section
				class="home__intro"
				v-html="home.components.header"
			/>

			<section class="home__content">

				<div class="home__teaser"
					v-html="home.components.teaser"
					@click="htmlToRouterLinks"
				></div>

				<div class="home__lists">

					<!-- Unfinished Modules -->
					<ListWithTitle v-if="home.components" :data="home.components.begun.modules">
						<template #title>
							{{ home.components.begun.title }}
						</template>
						<template #list="{data}">
							<ModuleItem :data="data" />
						</template>
					</ListWithTitle>

					<!-- Next Badges -->
					<ListGrid v-if="home.components && home.components.badges.items.length" :data="home.components.badges.items" class="list">
						<template #title>{{ home.components.badges.title }}</template>
						<template #list="{data}" >
							<ListGridItem
								:data="data"
								:status="data.earned"
								:subtitle="data.subtitle"
							/>
						</template>
						<template #footer>
							<router-link to="/profile" class="listgrid__footer">
								{{l10n.list}}<i class="icon-arrow-right"/>
							</router-link>
						</template>
					</ListGrid>

					<!-- Latest Modules -->
					<ListWithTitle v-if="home.components" :data="home.components.latest.modules">
						<template #title>
							{{ home.components.latest.title }}
						</template>
						<template #list="{data}">
							<ModuleItem :data="data" />
						</template>
					</ListWithTitle>

				</div>
			</section>

			<section
				v-if="home.components.sections"
				class="home__sections"
				v-html="home.components.sections"
			/>
		</div>
		<Loader v-else />
	</transition>
</template>

<script>

	import ListWithTitle from '~/components/General/ListWithTitle';
	import ListGrid from '~/components/General/ListGrid';
	import ListGridItem from '~/components/General/ListGridItem';
	import ModuleItem from '../Learn/Modules/ModuleItem';
	import Loader from '~/components/General/Loader';
	import Utility from '~/mixins/Utility';

	import {mapState, mapActions} from 'vuex';

	export default {
		name: 'Home',

		components: {
			ListWithTitle,
			ListGrid,
			ListGridItem,
			ModuleItem,
			Loader
		},

		mixins : [
			Utility
		],

		methods: {
			...mapActions('learn', ['getHome'])
		},

		computed: {
			...mapState('learn', ['home']),
			...mapState('global', ['l10n']),
			isLoaded() {
				return this.home.components;
			}
		},

		created() {
			this.getHome();
		}
	}
</script>

<style lang="scss">

	$layout-switch: $tablet;

	.intro {

		display: flex;
		flex-direction: column;
		position: relative;

		&__headline {
			margin: 0 $gutter;
			@include tablet {
				margin: $gutter*2 $gutter*4;
			}
			color: $white;
			font-size: $font-size-bigger;
			line-height: 1.5;
			font-weight: $font-weight-bold;
			position: absolute;
		}

		&__image {

			img {
				display: block;
				width: 100%;
				height: auto;

				@include tablet {
					width: calc(100% - #{$gutter*2});
					margin: $gutter auto 0;
				}
			}
		}
	}

	.home {

		&__teaser {

			@include h-gap;

			@include until($desktop) {
				position: absolute;
				transform: translateY( calc(-100% - #{$gutter}) );
				width: calc(100% - #{$gutter});
				background-color: $primary-lighter;
				box-shadow: $shadow-light;
			}

			@include tablet{
				background-color: $white;
			}

			@include in($tablet, $desktop) {
				max-width: 50%;
				margin-left: 0;
			}

			@include desktop {
				flex-grow: 0;
				max-width: 40%;
				align-self: flex-start;
				transform: translateY(-50%);
			}
		}

		&__content {
			position: relative;

			@include desktop {
				display: flex;
				max-width: $full-width;
				margin-left: auto;
				margin-right: auto;
			}
		}

		&__lists {
			flex: 1;
			margin: $gutter*2 0;

		}

		&__sections {
			margin: $big-gutter auto 0;
			padding-bottom: $big-gutter;
			background-color: $white;

			@include clear;

			@include tablet {
				max-width: $full-width;
				display: flex;

				> * { flex: 1; }
			}

			.section {
				@include desktop {
					font-size: $font-size-normal;
				}
			}

		}

		.listgrid {

			grid-template-columns: repeat(3, 1fr) !important;

			&__item {

				& > * { opacity: 1; }

				&__icon {
					background-color: transparent;
					&__img { filter: none; }
				}

				&__subtitle { color: $secondary-alternative; }
			}
		}

	}


</style>

<style lang="scss" scoped>

	.list {
		margin-top: $gutter;
		margin-bottom: $gutter*2;
	}

	.intro + .list {
		margin-top: calc(#{$gutter} + 1rem);
	}

</style>
