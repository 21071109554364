import baseService from "../services/baseService";
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "~/modules/Home/Home.vue";
import NotFound from "~/components/General/NotFound.vue";
import NotAuth from "~/components/General/NotAuth.vue";
import learnRoutes from "~/modules/Learn/route.js";
import store from "../store";

Vue.use (VueRouter);

const routes = [
	...learnRoutes,
	{
		path: '/',
		redirect: '/home',
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/inbox',
		name: 'Inbox',
		component: () => import('~/modules/Inbox/Inbox.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/profile',
		name: 'Profile',
		component: () => import('~/modules/Client/Profile.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/notauth',
		name: 'NotAuth',
		component: NotAuth
	},
	{
		path: '*',
		component: NotFound
	}
];

const router = new VueRouter ({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior: () => ({y: 0}),
	routes,
});

function auth (next) {

	// authQuery
	const authQuery = decodeURIComponent(window.location.search).substr(7) || process.env.TEST_TOKEN;

	baseService
		.auth (authQuery)
		.then (res => {
			if(res.status >= 400 || res.request.status >= 400) throw 'no Auth';

			store.commit ('client/setIsAuth', true);
			baseService.setAuthToken (res.data.jwt);
			store.dispatch ('client/getProfile');
			store.commit ('global/setL10n', res.data.l10n);

			next();
		})
		.catch (err => {
			next ({
				path: '/notauth',
			})
		})
}

router.beforeEach ((to, from, next) => {
	if (to.matched.some (record => record.meta.requiresAuth)) {
		if (store.getters['client/isAuth']) {
			next ()
		} else {
			auth (next)
		}
	} else {
		next ()
	}
});

export default router;
